<template>
    <section class="ol-product ol-product-detail">
        <div class="ol-wrapper ol-wrapper--large">
            <div class="ol-page">

                <h2 class="ol-page-title">PERTANYAAN YANG SERING DIAJUKAN</h2>

                <div class="ol-page__faq">
                    <div class="ol-page__faq-item">

                        <figure class="ol-page__faq-item-figure">
                            <img :srcset="require('@/assets/images/og-hero.webp')" alt="RumaKamu" title="RumaKamu">
                            <p>Akun</p>
                        </figure>

                        <div class="ol-product-content-detail__desc-spec ol-page__faq-item-content">

                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Bagaimana cara mengubah informasi akun saya ( nama, email, no. telp ) ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Untuk mengubah informasi akun, Anda dapat mengakses menu : Profil →  Informasi Akun →  Nama, Nomor Telepon, Email →   Simpan
                                    </div>
                                </div>

                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Bagaimana cara mengubah kata sandi saya ? <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Untuk mengubah kata sandi, Anda dapat mengakses menu : Profil → Ubah Kata Sandi →  Masukkan kata sandi lama → Masukkan kata sandi baru →  Simpan
                                    </div>
                                </div>

                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Bagaimana cara menambah alamat pengiriman saya ? <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Untuk menambah alamat pengiriman, Anda dapat mengakses menu : Profil →  Alamat → Tambah Alamat → Simpan. 
                                    Anda dapat memilih salah satu alamat Anda sebagai alamat utama pengiriman, jika Anda tidak merubah opsi pengiriman saat melakukan pemesanan maka pesanan otomatis terkirim ke alamat utama.
                                    </div>
                                </div>

                            </div>


                    </div>

                    <div class="ol-page__faq-item">

                        <figure class="ol-page__faq-item-figure">
                            <img :srcset="require('@/assets/images/og-hero.webp')" alt="RumaKamu" title="RumaKamu">
                            <p>Pemesanan</p>
                        </figure>

                        <div class="ol-product-content-detail__desc-spec ol-page__faq-item-content">

                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Bagaimana cara memesan 1 atau lebih barang dari MORE ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Anda dapat melakukan pemesanan untuk 1 barang atau lebih dengan cara :
                                    Buka detail barang yang Anda inginkan, klik → Masukan Keranjang .
                                    Jika Anda ingin memesan lebih dari 1 barang, Anda dapat memasukkan barang kedua dan seterusnya ke dalam keranjang belanja Anda dengan cara yang sama.
                                    Apabila Anda ingin melakukan proses checkout, klik → Keranjang 
                                    Periksa kembali pesanan Anda . Anda dapat menambahkan pesan (opsional) pada saat proses checkout ( contoh : Jika tidak ada warna putih diganti warna hitam ).
                                    Apabila Pesanan dan Total sudah sesuai, klik → Checkout

                                    </div>
                                </div>
                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Bagaimana cara saya melakukan pengecekan pemesanan yang saya buat ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Untuk melakukan pengecekan pemesanan, Anda dapat mengakses menu Profil → Pesanan  Di sana akan ditampilkan status pemesanan Anda secara keseluruhan.
                                    </div>
                                </div>
                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Apakah barang yang ditampilkan di MORE ready stock ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Barang yang ditampilkan di MORE adalah barang ready stock berjalan. Anda dapat memasukkan ke dalam keranjang belanja untuk barang ready.
                                    Untuk barang stock kosong, anda dapat menghubungi customer service kami di nomor {{allSetting.wa_no}} (Whatsapp) untuk informasi ketersediaan stock selanjutnya

                                    </div>
                                </div>
                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Setelah checkout , apakah masih bisa menambah pesanan lagi ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Apabila Anda sudah sampai di proses checkout, maka Anda tidak bisa menambah pesanan lagi di nomor pesanan yang sama. Jika ingin menambah pesanan, Anda harus membuat pesanan baru
                                    </div>
                                </div>
                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Metode pembayaran apa saja yang bisa digunakan di MORE ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Pembayaran di MORE dapat menggunakan :<br>
                                    1.	E-Wallet : LinkAja, ShopeePay QRIS , ShopeePay App, OVO, Dana<br>
                                    2.	Pembiayaan Kredit : Kredivo , Akulaku<br>
                                    3.	Virtual Account Bank : BCA, BNI, Danamon, Mandiri, Maybank<br>
                                    4.	Banking (non virtual account) : Maybank, Permata<br>
                                    5.	Minimarket : Alfagroup ( Alfamart, AlfaMidi, Alfa Express, Dandan )<br>

                                    </div>
                                </div>

                                <div>
                                    <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Apakah ada batas waktu maksimal untuk pembayaran pemesanan ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                    <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                    Batas waktu maksimal pembayaran pesanan Anda adalah : <br>
                                    1.	Virtual Account : maksimal 1 Jam dari pemesanan <br>
                                    2.	Banking ( non virtual account ) : maksimal 1 Jam dari pemesanan<br>
                                    3.	Minimarket : maksimal 1 Jam dari pemesanan<br>
                                    4.	E-Wallet : Direct langsung ke Login E-Wallet Anda<br>
                                    5.	Pembiayaan Kredit : Direct langsung ke Login Pembiayaan Kredit Anda<br>
                                    </div>
                                </div>
                                <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Apakah saya bisa membatalkan atau merubah pesanan setelah pembayaran ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Apabila pesanan Anda telah berhasil dibayar, Anda tidak bisa membatalkan atau merubah pesanan.
                                Pembatalan dan perubahan pesanan bisa dilakukan setelah checkout, sebelum pembayaran diterima.
                                </div>
                            </div>
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Berapa lama waktu yang dibutuhkan untuk proses pemesanan saya ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Proses pengecekan administrasi pemesanan kurang lebih 2 hari dari tanggal pesanan. Selanjutnya pesanan Anda akan masuk dalam proses pengemasan dan pengiriman. Estimasi proses pengemasan dan pengiriman 7-14 hari kerja ( menyesuaikan kondisi ekspedisi pengiriman )
                                </div>
                            </div>
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Di manakah saya bisa melakukan pengecekan status pesanan saya ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Anda dapat melakukan pengecekan status pesanan Anda di Profil → Pesanan → Detail pesanan . Status pesanan Anda akan terbagi menjadi 3 : Diproses , Dikirim dan Selesai
                                </div>
                            </div>
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Apakah saya bisa membeli lagi barang yang sudah saya beli sebelumnya ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Anda bisa membeli lagi barang yang sudah Anda beli sebelumnya selama stock masih ada. Untuk memudahkan, Anda cukup mengakses pesanan Anda sebelumnya di menu Profil → Pesanan → Detail Pesanan lalu klik → Beli Lagi
                                </div>
                            </div>
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Apakan saya bisa membeli lagi barang yang sebelumnya batal saya beli ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Anda bisa membeli lagi barang yang sebelumnya sudah Anda batalkan selama stock masih ada. Untuk memudahkan, Anda cukup mengakses pesanan Anda yang telah dibatalkan di menu Profil → Pesanan → Detail Pesanan, lalu klik → Beli Lagi dan melanjutkan ke proses checkout dan pembayaran.
                                </div>
                            </div>

                            </div>


                    </div>
                    <div class="ol-page__faq-item">
                        <figure class="ol-page__faq-item-figure">
                            <img :srcset="require('@/assets/images/og-hero.webp')" alt="RumaKamu" title="RumaKamu">
                            <p>Pengiriman</p>
                        </figure>
                        <div class="ol-product-content-detail__desc-spec ol-page__faq-item-content">
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Berapa ongkos kirim untuk pengiriman barang ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Ongkos kirim untuk pesanan adalah FREE . Biaya pengiriman sepenuhnya ditanggung pihak MORE
                                </div>
                            </div>
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Apakah area saya termasuk coverage area pengiriman MORE ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Untuk sementara ini, kami melayani pengiriman HANYA untuk area Jawa & Bali dengan layanan Bebas Ongkos Kirim
                                </div>
                            </div>
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Apakah saya bisa melakukan pengecekan status pengiriman pesanan saya ke ekspedisi ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Anda dapat melakukan pengecekan pengiriman pesanan Anda melalui ekspedisi partner yang kami gunakan. Anda dapat menghubungi customer service kami untuk mendapatkan nomer resi dan petunjuk untuk melacak pesanan Anda
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ol-page__faq-item">
                        <figure class="ol-page__faq-item-figure">
                            <img :srcset="require('@/assets/images/og-hero.webp')" alt="RumaKamu" title="RumaKamu">
                            <p>Pengembalian/Penggantian Produk</p>
                        </figure>
                        <div class="ol-product-content-detail__desc-spec ol-page__faq-item-content">
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Bagaimana cara pengajuan pengembalian / penggantian produk yang tidak sesuai ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Untuk pengaduan penggantian produk atau produk tidak sesuai dengan pesanan, Anda dapat menghubungi kami melalui email hello@morefurniture.id atau {{allSetting.wa_no}} (Whatsapp)<br>
                                Syarat pengajuan pengembalian dan penggantian produk :<br>
                                1.	Untuk produk yang tidak sesuai dengan pemesanan : Minimal 1 foto dan video saat produk diterima atau dibuka. Kondisi produk belum dirakit ( untuk produk rakitan ), produk harus dalam kondisi orisinil dan prima<br>
                                2.	Untuk Produk Cacat / Rusak : Minimal 1 foto dan video saat produk diterima dan proses unboxing. Lengkapi dengan detail foto bagian yang cacat / rusak.<br>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ol-page__faq-item">
                        <figure class="ol-page__faq-item-figure">
                            <img :srcset="require('@/assets/images/og-hero.webp')" alt="RumaKamu" title="RumaKamu">
                            <p>Offline Store</p>
                        </figure>
                        <div class="ol-product-content-detail__desc-spec ol-page__faq-item-content">
                            <div>
                                <h4 class="ol-product-content-detail__desc-spec-title" v-on:click="showDetail">Di manakah saya dapat membeli produk Olympic & Olymplast secara offline ?  <i class="ol-fa fas fa-angle-down"></i></h4>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:none;">
                                Untuk sementara ini kami hanya melayani pembelian melalui Online Store Official kami di morefurniture.id atau di Marketplace Official.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>
</template>
<script>
import BaseLayout from '@/components/landing/BaseLayout.vue';
import {mapActions,mapGetters} from "vuex";
export default {
    name: "FAQ",
    metaInfo() {
        return{
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components:{
        BaseLayout,
    },
    computed:{
        ...mapGetters('home',['allSetting']),

    },
    methods:{
        ...mapActions('home',['getSetting']),

        showDetail(e) {
            var o = e.target.classList.contains('active');
            if(o){
                e.target.children[0].innerHTML = "<i class='ol-fa fas fa-angle-down'></i>";
                e.srcElement.classList.remove("active");
                e.target.nextSibling.style.display = "none";
            }else{
                e.target.children[0].innerHTML = "<i class='ol-fa fas fa-angle-up'></i>";
                e.srcElement.classList.add("active");
                e.target.nextSibling.style = "block";
            }
        },
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
    },
    async mounted(){
        await this.getSetting();
        this.jqScrolling2top();
    }
}
</script>